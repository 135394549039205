{
	"private": true,
	"name": "gtfs-rt-inspector",
	"description": "Web app to inspect & analyze any GTFS Realtime feed.",
	"version": "1.0.0",
	"keywords": [
		"gtfs",
		"realtime",
		"gtfs-rt",
		"public transport",
		"transit"
	],
	"author": "Jannis R <mail@jannisr.de>",
	"homepage": "https://github.com/derhuerst/gtfs-rt-inspector",
	"repository": "derhuerst/gtfs-rt-inspector",
	"bugs": "https://github.com/derhuerst/gtfs-rt-inspector/issues",
	"license": "ISC",
	"type": "module",
	"engines": {
		"node": ">=14"
	},
	"scripts": {
		"lint": "eslint --ignore-path .gitignore .",
		"build:copy": "cp assets/* $(node -p 'require.resolve(\"mapbox-gl/dist/mapbox-gl.css\")') dist/",
		"build:bundle": "esbuild --loader:.js=jsx --jsx-import-source=preact --jsx=automatic --define:process.env.MAPBOX_TOKEN=\\'$MAPBOX_TOKEN\\' --bundle --minify --outfile=dist/main.js --sourcemap --target=chrome96,firefox91,safari14 index.js",
		"build": "npm run build:copy && npm run build:bundle",
		"build:watch": "esbuild --loader:.js=jsx --jsx-import-source=preact --jsx=automatic --define:process.env.MAPBOX_TOKEN=\\'$MAPBOX_TOKEN\\' --bundle --minify --outfile=dist/main.js --sourcemap --target=chrome96,firefox91,safari14 --watch index.js"
	},
	"dependencies": {
		"abort-controller": "^3.0.0",
		"buffer": "^6.0.3",
		"content-type": "^1.0.4",
		"core-js": "^3.17.2",
		"events": "^3.2.0",
		"fetch-periodic-sync": "^1.1.2",
		"fetch-ponyfill": "^7.1.0",
		"gtfs-rt-bindings": "^4.0.1",
		"lodash": "^4.17.20",
		"mapbox-gl": "^2.1.1",
		"ms": "^2.1.3",
		"object-inspect": "^1.11.0",
		"preact": "^10.5.7",
		"query-string": "^8.2.0",
		"regenerator-runtime": "^0.14.1",
		"url-template": "^3.1.0"
	},
	"devDependencies": {
		"esbuild": "^0.20.0",
		"eslint": "^8.12.0"
	},
	"browserslist": [
		"defaults"
	]
}
